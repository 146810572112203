/*--------------------------------------------------------------
FRIGOS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Bind
- Resize
- Go Back
- Close Droplist
- Select Droplist Option
- filterAppareils
- Slider Frigos
- Select Frigo
- Choose Frigo
- Slider Conduits
- Select Conduit
- Choose Conduit
--------------------------------------------------------------*/



class Frigos {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor ({module, sliderTransitionDuration, mobileLimit = 768}) {
		this.module = module;

		this.sections = module.getAll('.step');
		this.backBtns = module.getAll('.back button');
		this.mobileLimit = mobileLimit;
		this.resize();

		this.droplist = module.get('.droplist');
		this.droplistInput = this.droplist.get('input');
		this.droplistSelect = this.droplist.get('ul');
		this.droplistOptions = this.droplistSelect.children;
		this.droplistActive = null;

		this.sliderDuration = sliderTransitionDuration;

		// frigos
		this.sliderFrigos = module.get('#slider_frigos');
		this.sliderFrigosLeft = this.sliderFrigos.get('.left');
		this.sliderFrigosRight = this.sliderFrigos.get('.right');
		this.sliderFrigosUl = this.sliderFrigos.get('ul');
		this.sliderFrigosLis = this.sliderFrigosUl.children;
		this.sliderFrigosVisibleLis = [];
		this.sliderFrigosLis.foreach(li => this.sliderFrigosVisibleLis.unshift(li));
		this.sliderFrigosValBtns = this.sliderFrigosUl.getAll('.valider');
		this.sliderFrigosIndex = 0;
		if (this.mobile) {
			this.sliderFrigosLeft.classList.add('off');
			this.sliderFrigosLis[0].classList.add('center');
		} else {
			this.sliderFrigosLis[1].classList.add('center');
		}

		// conduits
		this.sliderConduits = module.get('#slider_conduits');
		this.sliderConduitsLeft = this.sliderConduits.get('.left');
		this.sliderConduitsRight = this.sliderConduits.get('.right');
		this.sliderConduitsUl = this.sliderConduits.get('ul');
		this.sliderConduitsLis = [];
		this.sliderConduitsUl.children.foreach(li => this.sliderConduitsLis.unshift(li));
		this.sliderConduitsValBtns = this.sliderConduitsUl.getAll('.valider');
		this.sliderConduitsIndex = 0;
		if (this.mobile) {
			this.sliderConduitsLeft.classList.add('off');
			this.sliderConduitsLis[0].classList.add('center');
		} else {
			this.sliderConduitsLis[1].classList.add('center');
		}

		this.conduits = module.getAll('#conduits li');
		this.conduitsBtns = module.getAll('#conduits .valider');

		this.types = module.getAll('.type');
		this.finalImg = module.get('.selected_photo');

		this.bind();
	}



	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind () {
		this.backBtns.foreach(btn => {
			btn.onclick = _ => this.goBack(btn);
		});
		this.droplistInput.onclick = _ => {
			this.droplistSelect.classList.toggle('visible');
		};
		document.body.on('click', e => this.closeDroplist(e.target));
		this.droplistOptions.foreach(option => {
			option.onclick = _ => this.selectDroplistOption(option);
		});

		// frigos
		this.sliderFrigosLeft.onclick = _ => this.slideFrigos(-1);
		this.sliderFrigosRight.onclick = _ => this.slideFrigos(1);
		this.sliderFrigosLis.foreach((frigo, i, array) => {
			frigo.onclick = _ => this.selectFrigo(frigo, i);
		});
		this.sliderFrigosValBtns.foreach((btn, i) => {
			btn.onclick = _ => this.chooseFrigo(i);
		});

		// conduits
		this.sliderConduitsLeft.onclick = _ => this.slideConduits(-1);
		this.sliderConduitsRight.onclick = _ => this.slideConduits(1);
		this.sliderConduitsLis.foreach((conduit, i, array) => {
			conduit.onclick = _ => this.selectConduit(conduit, i);
		});
		this.sliderConduitsValBtns.foreach((btn, i) => {
			btn.onclick = _ => this.chooseConduit(i);
		});

		window.on('resize', _ => this.resize());
	}



	/*--------------------------------------------------------------
	-	Resize
	--------------------------------------------------------------*/
	resize () {
		this.mobile = window.innerWidth <= this.mobileLimit;
	}



	/*--------------------------------------------------------------
	-	Go Back
	--------------------------------------------------------------*/
	goBack (btn) {
		let section = btn.parentNode.parentNode;
		section.classList.remove('on');
		section.previousElementSibling.classList.add('on');
		section.previousElementSibling.classList.remove('left');
	}



	/*--------------------------------------------------------------
	-	Close Droplist
	--------------------------------------------------------------*/
	closeDroplist (el) {
		if (!this.droplist.contains(el)) {
			this.droplistSelect.classList.remove('visible');
		}
	}



	/*--------------------------------------------------------------
	-	Select Droplist Option
	--------------------------------------------------------------*/
	selectDroplistOption (option) {
		if (this.droplistActive === option) {
			return;
		}
		if (this.droplistActive) {
			this.droplistActive.classList.remove('on');
		}
		option.classList.add('on');
		this.droplistActive = option;
		this.droplistInput.value = option.textContent;
		this.filterAppareils(option.getAttribute('data-val'));
		this.droplistSelect.classList.remove('visible');
	}



	/*--------------------------------------------------------------
	-	filterAppareils
	--------------------------------------------------------------*/
	filterAppareils (marque) {
		this.sliderFrigos.classList.add('out');

		// what will be the visible lis
		this.sliderFrigosVisibleLis = [];
		this.sliderFrigosLis.foreach(li => {
			if (
				marque === 'all' ||
				li.getAttribute('data-marque') === marque
			) {
				this.sliderFrigosVisibleLis.unshift(li);
			}
		});

		setTimeout(_ => {
			if (this.mobile) {
				this.sliderFrigosLeft.classList.add('off');
				if (this.sliderFrigosVisibleLis.length === 1) {
					this.sliderFrigosRight.classList.add('off');
				} else {
					this.sliderFrigosRight.classList.remove('off');
				}
			} else {
				// hide btns if less than 3 visible lis
				if (this.sliderFrigosVisibleLis.length < 3) {
					this.sliderFrigosLeft.classList.add('off');
					this.sliderFrigosRight.classList.add('off');
				} else {
					this.sliderFrigosLeft.classList.remove('off');
					this.sliderFrigosRight.classList.remove('off');
				}
			}

			// reset ul position
			this.sliderFrigosUl.style.transform = this.sliderFrigosUl.style.webkitTransform = 'none';
		}, this.sliderDuration/2);

		// hide / show lis
		setTimeout(_ => {
			this.sliderFrigosLis.foreach(frigo => {
				frigo.classList.remove('selected');
				frigo.classList.remove('center');
				if (this.sliderFrigosVisibleLis.indexOf(frigo) > -1) {
					frigo.classList.remove('hidden');
				} else {
					frigo.classList.add('hidden');
				}
			});

			// select the center one
			if (this.mobile) {
				this.sliderFrigosUl.classList.remove('lessThan3');
				this.sliderFrigosVisibleLis[0].classList.add('center');
			} else if (this.sliderFrigosVisibleLis.length < 3) {
				this.sliderFrigosUl.classList.add('lessThan3');
				this.sliderFrigosVisibleLis[0].classList.add('center');
			} else {
				this.sliderFrigosUl.classList.remove('lessThan3');
				this.sliderFrigosVisibleLis[1].classList.add('center');
			}
			this.sliderFrigosIndex = 0;

			this.sliderFrigos.classList.remove('out');
		}, this.sliderDuration);
	}



	/*--------------------------------------------------------------
	-	Slider Frigos
	--------------------------------------------------------------*/
	slideFrigos (dir) {
		let o = this.sliderFrigosVisibleLis.length < 3 ? 0 : 1;
		// let next = this.sliderFrigosVisibleLis[this.sliderFrigosIndex + dir + o];
		// if (!next) {
		// 	return;
		// }
		let centered;
		if (this.mobile) {
			centered = this.sliderFrigosIndex;
		} else {
			centered = this.sliderFrigosIndex + o;
		}
		this.sliderFrigosVisibleLis[centered].classList.remove('center');
		this.sliderFrigosIndex += dir;
		this.sliderFrigosVisibleLis[centered + dir].classList.add('center');
		if (this.sliderFrigosVisibleLis.length >= 3 || this.mobile) {
			let offset = this.sliderFrigosVisibleLis[0].clientWidth * -this.sliderFrigosIndex;
			this.sliderFrigosUl.style.transform = this.sliderFrigosUl.style.webkitTransform = `translateX(${offset}px)`;
		}
		if (this.sliderFrigosVisibleLis.length < 3 && !this.mobile) {
			this.sliderFrigosLeft.classList.add('off');
			this.sliderFrigosRight.classList.add('off');
		} else {
			let first = this.mobile ? 1 : 0;
			if (this.sliderFrigosIndex < first) {
				this.sliderFrigosLeft.classList.add('off');
			} else {
				this.sliderFrigosLeft.classList.remove('off');
			}
			if (!this.sliderFrigosVisibleLis[this.sliderFrigosIndex + 2 - first]) {
				this.sliderFrigosRight.classList.add('off');
			} else {
				this.sliderFrigosRight.classList.remove('off');
			}
		}
	}



	/*--------------------------------------------------------------
	-	Select Frigo
	--------------------------------------------------------------*/
	selectFrigo (frigo, i) {
		this.sliderFrigosVisibleLis.foreach(f => f.classList.remove('selected'));
		frigo.classList.add('selected');
		let centered = this.sliderFrigosUl.get('.center');
		if (frigo !== centered && !this.mobile) {
			let pos = this.sliderFrigosVisibleLis.indexOf(centered);
			if (this.sliderFrigosVisibleLis[pos - 1] === frigo) {
				this.slideFrigos(-1);
			} else {
				this.slideFrigos(1);
			}
		}
	}



	/*--------------------------------------------------------------
	-	Choose Frigo
	--------------------------------------------------------------*/
	chooseFrigo (i) {
		this.choosedCat = this.sliderFrigosLis[i].getAttribute('data-cat');
		let img = this.sliderFrigosLis[i].get('img');
		this.choosedImg = {
			src: img.src,
			alt: img.alt,
		};
		this.sections[0].classList.remove('on');
		this.sections[0].classList.add('left');
		this.sections[1].classList.add('on');
	}



	/*--------------------------------------------------------------
	-	Slider Conduits
	--------------------------------------------------------------*/
	slideConduits(dir) {
		let o = this.sliderConduitsLis.length < 3 ? 0 : 1;
		let centered;
		if (this.mobile) {
			centered = this.sliderConduitsIndex;
		} else {
			centered = this.sliderConduitsIndex + o;
		}
		this.sliderConduitsLis[centered].classList.remove('center');
		this.sliderConduitsIndex += dir;
		this.sliderConduitsLis[centered + dir].classList.add('center');
		if (this.sliderConduitsLis.length >= 3 || this.mobile) {
			let offset = this.sliderConduitsLis[0].clientWidth * -this.sliderConduitsIndex;
			this.sliderConduitsUl.style.transform = this.sliderConduitsUl.style.webkitTransform = `translateX(${offset}px)`;
		}
		if (this.sliderConduitsLis.length < 3 && !this.mobile) {
			this.sliderConduitsLeft.classList.add('off');
			this.sliderConduitsRight.classList.add('off');
		} else {
			let first = this.mobile ? 1 : 0;
			if (this.sliderConduitsIndex < first) {
				this.sliderConduitsLeft.classList.add('off');
			} else {
				this.sliderConduitsLeft.classList.remove('off');
			}
			if (!this.sliderConduitsLis[this.sliderConduitsIndex + 2 - first]) {
				this.sliderConduitsRight.classList.add('off');
			} else {
				this.sliderConduitsRight.classList.remove('off');
			}
		}
	}



	/*--------------------------------------------------------------
	-	Select Conduit
	--------------------------------------------------------------*/
	selectConduit (conduit, i) {
		this.sliderConduitsLis.foreach(f => f.classList.remove('selected'));
		conduit.classList.add('selected');
		let centered = this.sliderConduitsUl.get('.center');
		if (conduit !== centered && !this.mobile) {
			let pos = this.sliderConduitsLis.indexOf(centered);
			if (this.sliderConduitsLis[pos - 1] === conduit) {
				this.slideConduits(-1);
			} else {
				this.slideConduits(1);
			}
		}
	}



	/*--------------------------------------------------------------
	-	Choose Conduit
	--------------------------------------------------------------*/
	chooseConduit (i) {
		this.choosedConduit = this.sliderConduitsLis[i].getAttribute('data-cat');
		this.sections[1].classList.remove('on');
		this.sections[1].classList.add('left');
		this.sections[2].classList.add('on');
		let result = null;
		this.types.foreach(type => {
			if (
				type.getAttribute('data-cat') === this.choosedCat &&
				type.getAttribute('data-conduit') === this.choosedConduit
			) {
				type.classList.add('on');
			} else {
				type.classList.remove('on');
			}
		});
		this.finalImg.src = this.choosedImg.src;
		this.finalImg.alt = this.choosedImg.alt;
	}



}
