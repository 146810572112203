/*--------------------------------------------------------------
GMAP  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Append Gmap script
- Init
--------------------------------------------------------------*/



class Gmap {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor ({wrapper, APIKey, controls, center, zoom = 13, minZoom = 5, maxZoom = 20, pin, scrollwheel = false}) {
		this.wrapper = wrapper;
		this.APIKey = APIKey;
		this.controls = controls;
		this.center = center;
		this.zoom = zoom;
		this.minZoom = minZoom;
		this.maxZoom = maxZoom;
		this.scrollwheel = scrollwheel;
		this.pin = pin;
		this.style = [
			{
				'featureType': 'water',
				'elementType': 'geometry',
				'stylers': [{'color': '#e9e9e9'}, {'lightness': 17}]
			},
			{
				'featureType': 'landscape',
				'elementType': 'geometry',
				'stylers': [{'color': '#f5f5f5'}, {'lightness': 20}]
			},
			{
				'featureType': 'road.highway',
				'elementType': 'geometry.fill',
				'stylers': [{'color': '#ffffff'}, {'lightness': 17}]
			},
			{
				'featureType': 'road.highway',
				'elementType': 'geometry.stroke',
				'stylers': [{'color': '#ffffff'}, {'lightness': 29}, {'weight': 0.2}]
			},
			{
				'featureType': 'road.arterial',
				'elementType': 'geometry',
				'stylers': [{'color': '#ffffff'}, {'lightness': 18}]
			},
			{
				'featureType': 'road.local',
				'elementType': 'geometry',
				'stylers': [{'color': '#ffffff'}, {'lightness': 16}]
			},
			{
				'featureType': 'poi',
				'elementType': 'geometry',
				'stylers': [{'color': '#f5f5f5'}, {'lightness': 21}]
			},
			{
				'featureType': 'poi.park',
				'elementType': 'geometry',
				'stylers': [{'color': '#dedede'}, {'lightness': 21}]
			},
			{
				'elementType': 'labels.text.stroke',
				'stylers': [{'visibility': 'on'}, {'color': '#ffffff'}, {'lightness': 16}]
			},
			{
				'elementType': 'labels.text.fill',
				'stylers': [{'saturation': 36}, {'color': '#333333'}, {'lightness': 40}]
			},
			{
				'elementType': 'labels.icon',
				'stylers': [{'visibility': 'off'}]
			},
			{
				'featureType': 'transit',
				'elementType': 'geometry',
				'stylers': [{'color': '#f2f2f2'}, {'lightness': 19}]
			},
			{
				'featureType': 'administrative',
				'elementType': 'geometry.fill',
				'stylers': [{'color': '#fefefe'}, {'lightness': 20}]
			},
			{
				'featureType': 'administrative',
				'elementType': 'geometry.stroke',
				'stylers': [{'color': '#fefefe'}, {'lightness': 17}, {'weight': 1.2}]
			},
		];
		this.append_script();
	}



	/*--------------------------------------------------------------
	-	Append Gmap script
	--------------------------------------------------------------*/
	append_script () {
		let tag = document.createElement('script');
		tag.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.APIKey;
		tag.onload = _ => this.init();
		document.body.appendChild(tag);
	}



	/*--------------------------------------------------------------
	-	Init
	--------------------------------------------------------------*/
	init () {
		this.map = new google.maps.Map(this.wrapper, {
			center: this.center,
			zoom: this.zoom,
			minZoom: this.minZoom,
			maxZoom: this.maxZoom,
			disableDefaultUI: !this.controls,
			styles: this.style,
			scrollwheel: this.scrollwheel,
		});
		this.marker = new google.maps.Marker({
			position: this.center,
			animation: google.maps.Animation.DROP,
			map: this.map,
			icon: {
				url: this.pin.url,
				scaledSize: new google.maps.Size(this.pin.width, this.pin.height),
			},
		});
	}



}
