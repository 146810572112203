/*--------------------------------------------------------------
FAQ  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Toggle
- Open
- Close
--------------------------------------------------------------*/



class FAQ {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor ({list, items, questionsSelector, answersSelector, duration}) {
		this.items = list.getAll(items);
		this.questions = list.getAll(questionsSelector);
		this.answers = list.getAll(answersSelector);
		this.duration = duration;
		this.items.foreach((question, i) => {
			question.firstElementChild.onclick = _ => this.toggle(i);
		});
	}



	/*--------------------------------------------------------------
	-	Toggle
	--------------------------------------------------------------*/
	toggle (index) {
		let i = this.items.length;
		while (i--) {
			if (this.items[i].classList.contains('on')) {
				this.close(i);
			} else if (i === index) {
				this.open(i);
			}
		}
	}



	/*--------------------------------------------------------------
	-	Open
	--------------------------------------------------------------*/
	open (i) {
		let item = this.items[i];
		let qH = this.questions[i].clientHeight;
		let aH = this.answers[i].clientHeight;
		item.style.height = item.clientHeight + 'px';
		item.style.willChange = 'height';
		setTimeout(_ => {
			item.style.height = qH + aH + 'px';
			this.answers[i].classList.add('visible');
		}, 0);
		item.classList.add('on');
		setTimeout(_ => {
			item.style.height = 'auto';
			item.style.willChange = 'auto';
		}, this.duration);
	}



	/*--------------------------------------------------------------
	-	Close
	--------------------------------------------------------------*/
	close (i) {
		let item = this.items[i];
		let qH = this.questions[i].clientHeight;
		item.style.height = item.clientHeight + 'px';
		item.style.willChange = 'height';
		setTimeout(_ => {
			item.style.height = qH + 'px';
		}, 0);
		item.classList.remove('on');
		setTimeout(_ => {
			item.style.height = 'auto';
			item.style.willChange = 'auto';
			this.answers[i].classList.remove('visible');
		}, this.duration);
	}



}
