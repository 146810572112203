/*--------------------------------------------------------------
TIMEOUT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Step
- Stop
- Reset
--------------------------------------------------------------*/



class Timeout {



	/*--------------------------------------------------------------
		Constructor
	--------------------------------------------------------------*/
	constructor (duration, callback) {
		this.duration = duration;
		this.callback = callback;
		this.init = null;
		this.raf = requestAnimationFrame(t => this.step(t));
	}



	/*--------------------------------------------------------------
		Step
	--------------------------------------------------------------*/
	step (timestamp) {
		if (this.init === null) {
			this.init = timestamp;
		}
		if (timestamp - this.init < this.duration) {
			this.raf = requestAnimationFrame(t => this.step(t));
		} else {
			this.callback();
		}
	}



	/*--------------------------------------------------------------
		Stop
	--------------------------------------------------------------*/
	stop () {
		cancelAnimationFrame(this.raf);
	}



	/*--------------------------------------------------------------
		Reset
	--------------------------------------------------------------*/
	reset () {
		this.stop();
		this.init = null;
		this.raf = requestAnimationFrame(t => this.step(t));
	}



}
