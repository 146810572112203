/*--------------------------------------------------------------
ROUTING  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Pages
- Init
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Pages
--------------------------------------------------------------*/
const page = {
	locals: [
		'192.168.0.21',
		'prevost.quentin-webmaster.com',
		'societeprevost.fr',
	],
	init () {
		this.bind_popups();
		let pageID = $$.first('main').id;
		if (page[pageID]) {
			page[pageID]();
		}
		const header = $$.id('header');
		const burger = $$.id('burger');
		burger.on('click', _ => {
			header.classList.toggle('open');
		});
		this.reveal = new Reveal({
			selector: $$.all('.reveal'),
			offset: 200,
			bothDirections: true,
		});
		// new Pjax(page.locals);
	},
	home () {
		new Scroll({
			selector: $$.all('.scroll, #menu a, #faq-true a'),
			fixedHeader: $$.id('header'),
			duration: 1000,
		});
		new Heat({
			playground: $$.id('heat'),
			hoverEls: $$.all('.intro>*:not(video)'),
			text: $$.first('.intro h2').textContent,
			size: 1,
			speed: 1,
			min: 2,
			max: 8,
			acceleration: .5,
		});
		new SliderBtns({
			wrapper: $$.id('agrements-true'),
			slidesSelector: 'section',
			btnsSelector: 'button',
			init: true,
			duration: 400,
			autoplay: true,
			delay: 2000,
			pauseDuration: 30000,
			limit: 425,
		});
		new PrestSuppl({
			selector: $$.all('.liste_prestations>li'),
			btnClass: 'open_tarifs',
			openClass: 'tarifs_open',
			visibleDivClass: 'desc',
			hiddenDivClass: 'tarifs',
		});
		new Frigos({
			module: $$.id('module_appareils'),
			sliderTransitionDuration: 200,
		});
		new Slider({
			slider: $$.first('#securite-true .slider ul'),
			slides: $$.all('#securite-true .slider li'),
			btns: {
				left: $$.first('#securite-true .left'),
				right: $$.first('#securite-true .right'),
			},
			nbr: 3,
			autoplay: true,
			delay: 3000,
			pauseDuration: 30000,
			limit: 425,
		});
		new FAQ({
			list: $$.first('#faq-true ul'),
			items: 'li',
			questionsSelector: 'h3',
			answersSelector: 'p',
			duration: 300,
		});
		new Gmap({
			wrapper: $$.id('gmap'),
			APIKey: 'AIzaSyDnF8CNkQRkmnp8Y7J5m29zY5PJol7sz0Q',
			controls: false,
			center: {
				lat: parseFloat($$.id('lat').value),
				lng: parseFloat($$.id('lng').value),
			},
			pin: {
				url: '/wp-content/themes/alliancegaz2018/img/pictos/big-pin.png',
				width: 29,
				height: 38,
			},
		});
		new Contact({
			form: $$.first('#contact-true form'),
			path: '/wp-content/themes/alliancegaz2018/send-mail.php',
			alertTime: 3000,
		});
	},
	bind_popups () {
		// Open
		let btns = $$.all('.open_popup');
		btns.foreach(btn => {
			let popup_id = btn.getAttribute('data-popup');
			btn.onclick = _ => $$.id(popup_id).classList.add('on');
		});

		// Close
		$$.all('.popup').foreach(popup => {
			popup.get('.cache').onclick = _ => popup.classList.remove('on');
			popup.get('.close').onclick = _ => popup.classList.remove('on');
		});
	},
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
page.init();
