/*--------------------------------------------------------------
CONTACT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Submit
- Alert
--------------------------------------------------------------*/



class Contact {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor ({form, path, alertTime}) {
		this.form = form;
		this.path = path;
		this.alertTime = alertTime;
		this.btn = this.form.get('button[type=submit]');
		this.msg = document.createElement('p');
		this.msg.classList.add('alert');
		this.form.appendChild(this.msg);
		this.form.onsubmit = e => this.submit(e);
	}



	/*--------------------------------------------------------------
	-	Submit
	--------------------------------------------------------------*/
	submit (e) {
		if (this.btn.disabled) {
			return;
		}
		e.preventDefault();
		this.btn.disabled = true;
		this.msg.classList.add('out');
		ajax.post(
			this.path,
			new FormData(this.form)
		).then((msg, status) => {
			let classe = status === 200 ? 'success' : 'error';
			this.alert(msg, classe);
		});
	}



	/*--------------------------------------------------------------
	-	Alert
	--------------------------------------------------------------*/
	alert (message, classe) {
		this.btn.disabled = false;
		this.msg.textContent = message;
		this.msg.className = 'alert ' + classe;
		if (classe === 'success') {
			this.form.reset();
		}
		setTimeout(_ => {
			if (!this.btn.disabled) {
				this.msg.classList.add('out');
			}
		}, this.alertTime);
	}



}
