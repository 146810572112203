/*--------------------------------------------------------------
HEAT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Init
- Bind
- Animate
--------------------------------------------------------------*/



class Heat {



    /*--------------------------------------------------------------
    -    Constructor
    --------------------------------------------------------------*/
    constructor ({playground, size, speed, min, max, acceleration, text, hoverEls}) {
        this.playground = playground;
        this.size = size;
        this.speed = speed;
        this.min = min;
        this.max = max;
        this.acceleration = acceleration;
        this.text = text;
        this.count = 0;
        this.hovered = false;
        this.hoverEls = hoverEls;
		this.inited = false;
        this.init();
		window.addEventListener('resize', _ => this.inited || this.init());
    }



    /*--------------------------------------------------------------
    -    Init
    --------------------------------------------------------------*/
    init () {
		if (window.innerWidth <= 768) {
			return;
		}
		this.inited = true;
        this.app = new PIXI.Application({
            width: this.playground.clientWidth,
            height: this.playground.clientHeight,
            transparent: true,
            resolution: 2,
            autoResize: true,
        });
        this.playground.appendChild(this.app.view);

        const text = new PIXI.Text(this.text, {
            fontFamily: 'lateau',
            fontSize: 72,
            fontWeight: 700,
            fill: 0xffffff,
            align: 'center',
            wordWrap: true,
            wordWrapWidth: 600,
        });
        text.x = this.playground.clientWidth/2;
        text.y = this.playground.clientHeight/2;
        text.anchor.x = .5;
        text.anchor.y = .5;
        this.app.stage.addChild(text);

        const noise = '/wp-content/themes/alliancegaz2018/img/noise.jpg';
        this.noiseSprite = PIXI.Sprite.fromImage(noise);
        this.noiseSprite.scale.y = this.size;
        this.noiseSprite.scale.x = this.size;
        this.noiseSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
        this.app.stage.addChild(this.noiseSprite);

        this.noiseFilter = new PIXI.filters.DisplacementFilter(this.noiseSprite, this.min);
        this.app.stage.filters = [this.noiseFilter];

        this.app.ticker.add(_ => this.animate());
        this.bind();
    }



    /*--------------------------------------------------------------
    -    Bind
    --------------------------------------------------------------*/
    bind () {
        let i = this.hoverEls.length;
        while (i--) {
            this.hoverEls[i].on('mouseover', _ => this.hovered = true);
            this.hoverEls[i].on('mouseout', _ => this.hovered = false);
        }
        window.addEventListener('resize', _ => {
            this.app.renderer.resize(
                this.playground.clientWidth,
                this.playground.clientHeight
            );
        });
    }



    /*--------------------------------------------------------------
    -    Animate
    --------------------------------------------------------------*/
    animate () {
        this.count += this.speed;
        this.noiseSprite.y = this.count * -10;
        this.noiseSprite.x = this.count * 1.5;
        if (this.hovered) {
            var scale = Math.min(this.max, this.noiseFilter.scale.x + this.acceleration);
        } else {
            var scale = Math.max(this.min, this.noiseFilter.scale.x - this.acceleration);
        }
        this.noiseFilter.scale = {
            x: scale,
            y: scale,
        }
    }



}
