/*--------------------------------------------------------------
REVEAL  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- onScroll
- calcWindowOffset
- requestTick
- update
--------------------------------------------------------------*/



class Reveal {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor ({selector, offset = 300, bothDirections = false}) {
		this.els = selector;
		this.offset = offset;
		this.bothDirections = bothDirections;
		this.ticking = false;
		this.calcWindowOffset();
		// we wait 100ms before the first reveal (to animate what's on screen)
		setTimeout(_ => this.requestTick(), 100);
		window.addEventListener('scroll', _ => this.requestTick());
		window.addEventListener('resize', _ => this.calcWindowOffset());
	}



	/*--------------------------------------------------------------
	-	calcWindowOffset
	--------------------------------------------------------------*/
	calcWindowOffset () {
		this.windowOffset = window.innerHeight - this.offset;
		this.requestTick();
	}



	/*--------------------------------------------------------------
	-	requestTick
	--------------------------------------------------------------*/
	requestTick () {
		// if the last update is finished
		if(!this.ticking) {
			// we start a new rAF
			requestAnimationFrame(_ => this.update());
			// we're saying the update is currently running
			this.ticking = true;
		}
	}



	/*--------------------------------------------------------------
	-	update
	--------------------------------------------------------------*/
	update () {
		// first loop is going to do all the reflows (since we use offsetTop)
		let elTops = [];
		let i = this.els.length;
		while (i--) {
			// we use getBoundingClientRect in order to fetch also the absolute elements
			// because offsetTop + window.pageYOffset works only for relative elements
			elTops[i] = this.els[i].getBoundingClientRect().top;
		}

		// second loop is going to go through the els and add the class with classlist
		i = this.els.length;
		while (i--) {
			if(elTops[i] < this.windowOffset) {
				this.els[i].classList.add('in');
			} else if (this.bothDirections) {
				this.els[i].classList.remove('in');
			}
		}

		// allow further rAFs to be called
		this.ticking = false;
	}



}
