/*--------------------------------------------------------------
SLIDER WITH BTNS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Bind
- Autoplay
- Animate Height
--------------------------------------------------------------*/



class SliderBtns {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor ({wrapper, btnsSelector, slidesSelector, init, duration, autoplay, delay, pauseDuration, limit}) {
		this.wrapper = wrapper;
		this.btns = wrapper.getAll(btnsSelector);
		this.slides = wrapper.getAll(slidesSelector);
		this.duration = duration;
		this.oldIndex = 0;
		this.newIndex = 0;
		this.running = false;
		this.limit = limit;
		this.bind();
		if (init) {
			this.btns[0].classList.add('on');
			this.slides[0].classList.add('on');
		}
		if (autoplay) {
			this.delay = delay;
			this.pauseDuration = pauseDuration;
			this.autoplay();
		}
	}



	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind () {
		this.btns.foreach((btn, i) => {
			btn.onclick = _ => {
				this.autoplaying = false;
				if (this.running || btn.classList.contains('on')) {
					return;
				}
				this.newIndex = i;
				this.animateHeight();
			};
		});
	}



	/*--------------------------------------------------------------
	-	Autoplay
	--------------------------------------------------------------*/
	autoplay () {
		this.autoplaying = true;
		let start = null;
		const step = timestamp => {
			if (start === null) {
				start = timestamp;
			}
			if (window.innerWidth <= this.limit) {
				return;
			}
			let delay = this.autoplaying ? this.delay : this.pauseDuration;
			if (timestamp - start >= delay) {
				start = null;
				let offsetTop = this.wrapper.getBoundingClientRect().top;
				if (offsetTop > 0 && offsetTop < window.innerHeight) {
					this.autoplaying = true;
					this.newIndex = (this.newIndex + 1) % this.btns.length;
					this.animateHeight();
				}
			}
			requestAnimationFrame(step);
		}
		requestAnimationFrame(step);
	}



	/*--------------------------------------------------------------
	-	Animate Height
	--------------------------------------------------------------*/
	animateHeight () {
		this.running = true;

		let oldSlide = this.slides[this.oldIndex];
		let newSlide = this.slides[this.newIndex];

		let oldSlideHeight = oldSlide.clientHeight;
		let newSlideHeight = newSlide.clientHeight;
		let oldWrapperHeight = this.wrapper.clientHeight;
		let newWrapperHeight = (oldWrapperHeight - oldSlideHeight) + newSlideHeight;

		this.btns[this.newIndex].classList.add('on');
		this.btns[this.oldIndex].classList.remove('on');

		this.wrapper.classList.add('out');
		this.wrapper.style.willChange = 'height';
		this.wrapper.style.height = oldWrapperHeight + 'px';
		setTimeout(_ => {
			newSlide.classList.remove('out');
			this.wrapper.style.height = newWrapperHeight + 'px';
		}, 50);
		setTimeout(_ => {
			newSlide.classList.add('on');
			oldSlide.classList.remove('on');
			this.wrapper.classList.remove('out');
		}, this.duration / 2 + 25);
		setTimeout(_ => {
			this.wrapper.style.height = 'auto';
			this.wrapper.style.willChange = 'auto';
			this.oldIndex = this.newIndex;
			this.running = false;
		}, this.duration + 50);
	}



}
