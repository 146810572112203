/*--------------------------------------------------------------
SLIDER  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Slide
--------------------------------------------------------------*/



class Slider {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor ({slider, slides, btns, nbr, autoplay, delay, pauseDuration, limit}) {
		this.slider = slider;
		this.slides = slides;
		this.left = btns.left;
		this.right = btns.right;
		this.nbr = nbr;
		this.index = 0;
		this.left.onclick = _ => {
			this.autoplaying = false;
			this.slide(-1);
		};
		this.right.onclick = _ => {
			this.autoplaying = false;
			this.slide(1);
		};
		this.limit = limit;
		if (autoplay) {
			this.delay = delay;
			this.pauseDuration = pauseDuration;
			this.autoplay();
		}
	}



	/*--------------------------------------------------------------
	-	Slide
	--------------------------------------------------------------*/
	slide (dir) {
		if (window.innerWidth <= this.limit) {
			return;
		}
		this.index += dir;
		let offset = this.slides[0].clientWidth * -this.index;
		this.slider.style.transform = this.slider.style.webkitTransform = `translateX(${offset}px)`;
		if (this.index + this.nbr >= this.slides.length) {
			this.right.classList.add('off');
		} else {
			this.right.classList.remove('off');
		}
		if (this.index === 0) {
			this.left.classList.add('off');
		} else {
			this.left.classList.remove('off');
		}
	}



	/*--------------------------------------------------------------
	-	Autoplay
	--------------------------------------------------------------*/
	autoplay () {
		this.autoplaying = true;
		let start = null;
		const step = timestamp => {
			if (start === null) {
				start = timestamp;
			}
			let delay = this.autoplaying ? this.delay : this.pauseDuration;
			if (timestamp - start >= delay) {
				start = null;
				let offsetTop = this.slider.getBoundingClientRect().top;
				if (offsetTop > 0 && offsetTop < window.innerHeight) {
					this.autoplaying = true;
					if (this.index + this.nbr >= this.slides.length) {
						let back = this.slides.length - this.nbr;
						this.slide(-back);
					} else {
						this.slide(1);
					}
				}
			}
			requestAnimationFrame(step);
		}
		requestAnimationFrame(step);
	}



}
