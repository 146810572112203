/*--------------------------------------------------------------
PRESTATIONS COMPLÉMENTAIRES  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Bind
--------------------------------------------------------------*/



class PrestSuppl {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor ({selector, btnClass, openClass, visibleDivClass}) {
		this.els = selector;
		this.btnClass = btnClass;
		this.openClass = openClass;
		this.visibleDivClass = visibleDivClass;
		this.bind();
	}



	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind () {
		this.els.foreach(el => {
			let btn = el.get('.' + this.btnClass);
			if (btn) {
				btn.onclick = _ => {
					el.classList.toggle(this.openClass);
					let offset = el.get('img').clientHeight;
					el.get('.' + this.visibleDivClass).style.transform = `translateY(-${offset}px)`;
				};
			}
		});
		window.on('resize', _ => {
			this.els.foreach(el => {
				el.classList.remove(this.openClass);
			});
		});
	}



}
