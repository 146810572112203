/*--------------------------------------------------------------
PJAX  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Scroll
- Cache
- Constructor
- Check
- Fetch
- Parse
- Replace
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Scroll
--------------------------------------------------------------*/
if ('scrollRestoration' in history) {
	history.scrollRestoration = 'manual';
}



/*--------------------------------------------------------------
-	Cache
--------------------------------------------------------------*/
const cache = {};



class Pjax {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor (locals) {
		this.pending = false;
		window.addEventListener('popstate', _ => this.fetch(true));
		document.addEventListener('click', e => {
			var el = e.target;
			while (el && !el.href) {
				el = el.parentNode;
			}
			if (this.check(el, locals)) {
				e.preventDefault();
				history.pushState(null, null, el.href);
				this.fetch();
				return;
			}
		});
	}



	/*--------------------------------------------------------------
	-	Check
	--------------------------------------------------------------*/
	check (el, locals) {
		return (
			el &&
			el.target != '_blank' &&
			!el.hash &&
			locals.indexOf(el.host) > -1
		);
	}



	/*--------------------------------------------------------------
	-	Fetch
	--------------------------------------------------------------*/
	fetch (history) {
		if (this.pending) return;

		this.pending = true;
		document.body.classList.add('changing');
		let url = window.location.href;

		if (cache[url]) {
			setTimeout(_ => this.parse(cache[url]), 200);
		} else {
			ajax.get(url).then(
				html => {
					cache[url] = html;
					this.parse(html);
				},
				err => document.body.classList.remove('changing')
			);
		}
	}



	/*--------------------------------------------------------------
	-	Parse
	--------------------------------------------------------------*/
	parse (html) {
		let dom = document.createElement('div');
		dom.innerHTML = html;

		// Title
		let title = dom.tags('title')[0].textContent;
		document.title = title;

		// Body
		this.old_body = {
			header: document.body.get('#header'),
			main: document.body.get('#main'),
			footer: document.body.get('#footer'),
		}
		this.new_body = {
			header: dom.get('#header'),
			main: dom.get('#main'),
			footer: dom.get('#footer'),
		}
		this.id = this.new_body.main.getAttribute('data-id');

		this.replace(history);
	}



	/*--------------------------------------------------------------
	-	Replace
	--------------------------------------------------------------*/
	replace (history) {
		document.body.id = this.id;

		if (this.old_body.header) {
			document.body.removeChild(this.old_body.header);
			document.body.removeChild(this.old_body.footer);
		}
		if (this.new_body.header) {
			document.body.appendChild(this.new_body.header);
			document.body.appendChild(this.new_body.footer);
		}
		document.body.replaceChild(this.new_body.main, this.old_body.main);

		document.body.classList.remove('changing');
		window.scrollTo(0, 0);
		this.pending = false;
		page.common.bind();
	}



}
