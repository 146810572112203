/*--------------------------------------------------------------
SCROLL  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Animate
- Scroll
--------------------------------------------------------------*/



class Scroll {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor ({selector, fixedHeader, duration}) {
		this.header = fixedHeader;
		this.duration = duration;
		this.pending = false;
		selector.foreach(btn => {
			btn.on('click', e => this.animate(e));
		});
	}



	/*--------------------------------------------------------------
	-	Animate
	--------------------------------------------------------------*/
	animate (e) {
		e.preventDefault();
		if (this.pending) {
			return;
		}
		this.pending = true;
		let hash = e.target.hash.substr(1);
		let target = $$.id(hash);
		if (target) {
			this.disableScroll();
			history.pushState(null, null, '#' + hash);
			let targetRect = target.getBoundingClientRect();
			let offset = target.getBoundingClientRect().top;
			this.header.classList.remove('open');

			if (document.body.clientHeight - target.offsetTop < window.innerHeight) {
				offset = document.body.clientHeight - window.pageYOffset - window.innerHeight;
			}

			this.main = $$.id('home');
			this.main.style.transition = (this.duration/1000) + 's cubic-bezier(.7, 0, .3, 1)';
			this.main.style.transform = `translateY(${offset * -1}px)`;
			let reveal = setInterval(_ => page.reveal.requestTick(), 1);
			setTimeout(_ => {
				clearInterval(reveal);
				let newScroll = window.pageYOffset + offset;
				window.scrollTo(0, newScroll);
				this.main.style.transition = `none`;
				this.main.style.transform = `none`;
				this.enableScroll();
				this.pending = false;
			}, this.duration);
		}
		return false;
	}



	/*--------------------------------------------------------------
	-	Scroll
	--------------------------------------------------------------*/
	disableScroll () {
		window.on('mousewheel', this.prevent);
		window.on('DOMMouseScroll', this.prevent);
		window.on('touchmove', this.prevent);
	}
	enableScroll () {
		window.off('mousewheel', this.prevent);
		window.off('DOMMouseScroll', this.prevent);
		window.off('touchmove', this.prevent);
	}
	prevent (e) {
		e.preventDefault();
	}



}
