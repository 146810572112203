/*--------------------------------------------------------------
GALERIE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Bind
- Animate
--------------------------------------------------------------*/



class Galerie {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor (id, delay) {
		this.index = 0;
		this.delay = delay;
		this.gal = $$.id(id);
		this.prev = this.gal.get('.prev');
		this.next = this.gal.get('.next');
		this.folio = this.gal.get('.folio');
		this.imgs = this.gal.tags('figure');
		this.length = this.imgs.length;
		if (this.next) {
			this.bind();
		}
	}



	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind () {
		this.prev.onclick = _ => this.animate(-1);
		this.next.onclick = _ => this.animate(1);
	}



	/*--------------------------------------------------------------
	-	Animate
	--------------------------------------------------------------*/
	animate (dir) {
		if (this.locked) return;
		this.locked = true;

		let old = this.imgs[this.index];
		this.index = (this.index + this.length + dir) % this.length;
		let current = this.imgs[this.index];
		current.style.zIndex = 1;

		if (dir == 1) { // Next
			if (this.index == 0) {
				this.imgs.foreach(img => img.className = 'r');
			} else {
				old.classList.add('l');
			}
		} else { // Prev
			if (this.index == this.length - 1) {
				this.imgs.foreach(img => img.className = 'l');
			} else {
				old.classList.add('r');
			}
		}
		current.className = '';
		this.folio.textContent = this.index + 1 + '/' + this.length;

		new Timeout(this.delay, _ => {
			current.style.zIndex = 0;
			this.locked = false;
		});
	}



}
